
import {
  Component,
  Vue,
  Watch,
  Prop,
} from 'vue-property-decorator';
import { isNull } from 'lodash';
import ActionButton from '@/components/Button/Button.vue';
import { Filter, Metadata } from '@/types/ListTypes';

const Chip = () => import(
  /* webpackChunkName: "chip" */
  /* webpackPrefetch: false */
  '@/components/commonComponents/Chip.vue'
);

@Component({
  name: 'MultiselectFilter',
  methods: { isNull },
  components: {
    ActionButton,
    Chip,
  },
})
export default class Multiselect extends Vue {
  @Prop({ required: true })
  private readonly filter!: Filter;

  private inputValues: Array<number> = [];

  public getFilterOptions(): Array<{ text: string, value: unknown }> {
    const options = this.filter.metadata?.options as Array<{const: unknown, title: string, metadata?: Metadata }>;
    return options.map((item, key) => {
      if (!item.metadata) {
        return ({ text: item.title, value: item.const });
      }

      return ({
        text: item.title, value: item.const, textColor: item.metadata.label_text_color, labelColor: item.metadata.label_color, id: key,
      });
    });
  }

  @Watch('filter.data', { immediate: true })
  private syncFilterDataWithInputData(): void {
    this.inputValues = this.filter.data as Array<number> ?? [];
  }

  public applyFilter(): void {
    this.filter.data = this.inputValues;
    this.filter.applied = this.inputValues.length > 0;

    this.$emit('fetchData');
  }
}
